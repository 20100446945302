import ReactDOM from "react-dom";
import React from "react";
import headshots from "../assets/images/headshots/*.png";

const modalRoot = document.getElementById("modal");

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

class SpeakerModal extends React.Component {
  render() {
    const image = headshots[this.props.name.toLowerCase().replace(/ /g, "_")];
    return (
      <Modal>
        <div className="speaker__modal">
          <div className="speaker__close" onClick={this.props.onClose} />
          <img className="speaker__image" src={image} />
          <h3 className="speaker__name">{this.props.name}</h3>
          <h4 className="speaker__title">{this.props.title}</h4>
          <p className="speaker__bio">{this.props.bio}</p>
        </div>
      </Modal>
    );
  }
}

export default SpeakerModal;
