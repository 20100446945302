import { TimelineMax, TweenLite, Linear } from "gsap/all";
import ScrollMagic from "scrollmagic";
import React from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import "scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js";
import "scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min.js";
import "normalize.css";
import "./assets/stylesheets/style.scss";
import payload from './payload';

import Programme from "./components/Programme";

import images from "./assets/images/animation/*.png";

let obj = { i: 0 };
const $img = document.querySelector('img.hero__animation')

let preload = {}
Object.values(images).forEach(src => {
  const image = new Image()
	image.src = src
  preload[src] = image
});

const controller = new ScrollMagic.Controller();
const tween = TweenLite.to(obj, 0.5, {
  i: Object.keys(images).length - 1,
  roundProps: "i",
  repeat: 0,
  immediateRender: true,
  autoKill: false,
  force3D: true,
  ease: Linear.easeNone,
  onUpdate: () => {
    const num = (obj.i).toString().padStart(5, '0');
    const image = images[num];
    $img.src = preload[image].src
  }
});

const heroAnimation = new ScrollMagic.Scene({
  triggerElement: isMobile ? 'header' : '.hero',
  triggerHook: 0,
  offset: -50,
  duration: 20 * Object.keys(images).length
})
      .setPin(".hero__container")
      .setTween(tween)
      .addTo(controller);

const headerAnimation = new ScrollMagic.Scene(
  {
    triggerHook: 0,
    triggerElement: '.hero'
  })
      .setPin("header")
      .addTo(controller)

headerAnimation.addTo(controller);

const mountNode = document.querySelector("section.programme");
ReactDOM.render(<Programme {...payload} />, mountNode);
