import React from "react";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import Speaker from "./Speaker";
import ScrollMagic from "scrollmagic";

class Session extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
    this.pin = React.createRef();
    this.trigger = React.createRef();
  }

  componentDidMount() {}

  render() {
    return (
      <div
        ref={this.trigger}
        className="session"
        onClick={this.props.handleClick}
      >
        <div className="session__header" ref={this.pin}>
          <div>
            <h1 className="session__header-title">{this.props.title}</h1>
            <h2 className="session__header-subtitle">{this.props.subtitle}</h2>
          </div>
        </div>
        <div className="session__speakers">
          {this.props.speakers.map(speaker => (
            <Speaker {...speaker} key={speaker.title} />
          ))}
        </div>
      </div>
    );
  }
}

export default Session;
