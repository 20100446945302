import React from "react";
import Session from "./Session";

class Programme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSessionIndex: 0
    };
  }

  render() {
    return (
      <div>
        {this.props.sessions.map((session, index) => (
          <Session
            {...session}
            key={index}
            collapsed={this.state.openSessionIndex != index}
          />
        ))}
      </div>
    );
  }
}

export default Programme;
