const payload = {
  sessions: [
    {
      title: "Panel #1",
      subtitle: "Control",
      speakers: [
        {
          name: "Neelay Patel",
          title: "BBC, Director of TV & Radio",
          bio:
            "Neelay leads the strategy, development, delivery and innovation activity for the future of BBC TV, Radio & Music. He was previously a Senior Vice President at the Economist and AOL’s European Commercial Director. He’s also an angel investor who has launched and scaled businesses in Europe, North America, Asia and the Middle East.",
          imageUrl: "assets/images/neelay_patel.png"
        },
        {
          name: "Cable Daniel Dreyfus",
          title: "Babylon, Director of Global Marketing",
          bio:
            "Formerly Head of Marketing at Google and a Brand Manager at Apple, Cable’s now with Babylon, an organization that’s aiming to put an accessible and affordable health service in the hands of everyone on earth. She runs the marketing for AI services and the GP at Hand proposition, in partnership with the NHS.",
          imageUrl: "assets/images/cable_daniel_dreyfus.png"
        },
        {
          name: "Phil Curtis",
          title: "Allianz Global Investors, Head of Global HR Operations",
          bio:
            "Phil covers a variety of topics from the design of the employee experience, through to performance management. In 2018, his team developed a completely new employee value proposition. Before joining Allianz Group in 2012, Phil worked in various senior HR roles, mostly in investment and private banking.",
          imageUrl: "assets/images/phil_curtis.png"
        },
        {
          name: "Meg Nakamura",
          title: "ShiftPayments, Co-Founder",
          bio:
            "Meg co-founded Shift with Greg Kidd in 2014. Shift makes it easy for users to spend any ‘store of value’, from bitcoin to loyalty points, at existing merchants accepting credit cards. She’s an investor who’s focused on the potential of cryptocurrencies and an adviser for Bloom Protocol, which provides decentralized credit scoring.",
          imageUrl: "assets/images/meg_nakamura.png"
        }
      ]
    },
    {
      title: "Panel #2",
      subtitle: "Scale",
      speakers: [
        {
          name: "Michelle McEttrick",
          title: "Tesco, Group Brand Director",
          bio:
            "Michelle has spent equal time in client marketing jobs and in agencies including McCann-Erickson, BBDO and BBH working across brands like British Airways and Coca-Cola. Client roles have included the VP/Marketing for allrecipes.com and MD, Group Brand and Marketing for Barclays. She’s currently responsible for Brand and Customer Proposition development across the Tesco group.",
          imageUrl: "assets/images/michelle_mcettrick.png"
        },
        {
          name: "Claire Davenport",
          title: "Hello Fresh, CEO",
          bio:
            "Prior to joining Hello Fresh as UK CEO in 2017, Claire ran VoucherCodes.co.uk. She’s been part of the executive team of a range of disruptor and tech brands, including FutureLearn and Skype. She was Executive VP at Europe's largest broadcasting company, RTL Group, and started out as a banker at Goldman Sachs.",
          imageUrl: "assets/images/claire_davenport.png"
        },
        {
          name: "Natalie Slack",
          title: "Black Milq, Founder",
          bio:
            "After working as a product developer in the food industry with Tesco, Propercorn and Innocent, Natalie decided to go it alone in 2017. She sold the first scoops of 100% plant-based Black Milq ice cream at Hackney Downs Vegan Market and has since built a following of health-conscious, environmentally aware consumers.",
          imageUrl: "assets/images/natalie_slack.png"
        },
        {
          name: "Yasha Estraikh",
          title: "Piper Private Equity, Associate Partner",
          bio:
            "Yasha is part of a small team investing in the UK's leading fast-growing consumer brands, including Monica Vinader, Boden, Flat Iron, Frame, Propercorn, Bottlegreen and many others. He specialises in formulating insight and developing brand and communications strategies for businesses with £3-£25m turnover.",
          imageUrl: "assets/images/yasha_estraikh.png"
        }
      ]
    },
    {
      title: "Workshop",
      subtitle: "Speed",
      speakers: [
        {
          name: "Marcus Fairs",
          title: "Dezeen, Founder and Editor in Chief",
          bio: "Marcus launched Dezeen in 2006 and it’s regarded as the most influential design website in the world, attracting over two million visitors every month. He is the first digital journalist to be awarded an Honorary Fellowship of RIBA and he was named one of the creative industry’s most influential figures by the Hospital Club.",
          imageUrl: "assets/images/marcus_fairs.png"
        },
        {
          name: "CJ Fahey",
          title: "Vice TV and Studios, General Manager",
          bio: "CJ is responsible for the creative and strategic development of VICE Media’s international channels. He oversees all TV output and previously launched VICELAND in the US, working alongside Spike Jonze on the development of its award-winning content slate.",
          imageUrl: "assets/images/cj_fahey.png"
        }
      ]
    },
    {
      title: "Panel #3",
      subtitle: "People",
      speakers: [
          {
            name: "Dan Brooke",
            title: "Channel 4, Chief Marketing and Communications Officer",
            bio: "Dan heads up Marketing, Press, 4Creative and Corporate Relations across the Channel 4 Group. Under his leadership, Channel 4 has been awarded the Marketing Society’s Brand of the Year, Best Diverse Company at the National Diversity Awards, Campaign’s Advertiser of the Year and Creative Review’s Agency of the Year.",
            imageUrl: "assets/images/dan_brooke.png"
          },
        {
          name: "Steven Appleyard",
          title: "Boiler Room, Chief Business Development Officer",
          bio: "Steven is a music and marketing expert. He’s helped grow Boiler Room, a global online music broadcasting platform, from a disruptive cultural project into one of the most influential, respected brands in youth culture. It now has an audience of multiple millions and partners with the likes of Netflix, Google and Pernod Ricard.",
          imageUrl: "assets/images/steven_appleyard.png"
        }, {
          name: "Gail Parker",
          title: "RSA + More Th>n, CMO",
          bio: "Gail leads a team at RSA, one of the world’s leading insurance groups, which operates in 140 countries. She looks after the More Th>n brand and works with UK partners including John Lewis, Argos, Tesco and M&S. Before her current role, she was Head of Marketing at British Gas and Royal Mail.",
          imageUrl: "assets/images/gail_parker.png"
        },
        {
          name: "Martin Stead",
          title: "Nutmeg, CEO",
          bio: "At Nutmeg, Martin aims to democratise wealth management and empower investors. He has a track record of delivering organic growth in mass, b2c businesses including P&G and EDF Energy. He was recognised by the Financial Times as one of their top 100 LGBT Executives and is a Fellow of the Marketing Society.",
          imageUrl: "assets/images/martin_stead.png"
        }
      ]
    }
  ]
}

export default payload;
