import React from "react";
import { isMobile } from "react-device-detect";
import SpeakerModal from "./SpeakerModal";
import headshots from "../assets/images/headshots/*.png";

class Speaker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  render() {
    if (this.state.modal) {
      return (
        <SpeakerModal {...this.props} onClose={this.handleClose.bind(this)} />
      );
    } else {
      const image = headshots[this.props.name.toLowerCase().replace(/ /g, "_")];
      return (
        <div className="speaker" onClick={this.handleClick.bind(this)}>
          <img className="speaker__image" src={image} />
          <h3 className="speaker__name">{this.props.name}</h3>
          <h4 className="speaker__title">{this.props.title}</h4>
          <p className="speaker__bio">{this.props.bio}</p>
        </div>
      );
    }
  }

  handleClick() {
    if (isMobile) {
      this.setState({ modal: true });
    }
  }
  handleClose() {
    this.setState({ modal: false });
  }
}

export default Speaker;
